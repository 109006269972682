<template>
  <v-container fluid>
    <base-form
      @submit="handleSubmit"
      @cancel="handleCancel"
    />
  </v-container>
</template>

<script>

  import { mapActions } from 'vuex';

  export default {
    components: {
      BaseForm: () => import('./BaseForm')
    },
    data () {
      return {}
    },

    computed: {
    },

    created () {
    },

    methods: {
      ...mapActions({
        feeRuleCreate: 'feeRule/create'
      }),

      handleSubmit (data) {
        this.feeRuleCreate(data).then(() => {
          this.$router.push({name: 'feeRule'})
        })
      },

      handleCancel() {
        this.$router.go(-1)
      }
    }
  }
</script>
